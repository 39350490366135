
<template>




  <div class="padding-fixed">
    <div class="common-bg padding-50">
      <div class="container">

        <!--  loading bg      -->
        <div class="f5-bg" v-if="loadingBg"></div>
        <!--  loading bg      -->

        <div class="news-return"  @click="returnClick" style="cursor: pointer">
          <i class="layui-icon layui-icon-left"></i> 返回
        </div>
        <div class="news-content padding-top-50">
          <h1>{{ title }}</h1>
          <img :src="image" class="cover-img img-center">
          <div class="news-editor padding-top-50">
            <div v-html="content"></div>
          </div>
        </div>

        <div class="next-news padding-top-50" v-if="next_page.title!= null && next_page.id!= null">
          <div>
            下一篇 -
          </div>
          <div @click="nextNews" class="next-news-content">
            <div class="next-news-title">
              {{next_page.title}}
            </div>
            <div class="ac-arr">
              <i class="layui-icon layui-icon-right"></i>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>

</template>

<script>
import {getNewsDetail} from "@/network/home";

export default {
  name: "newsDetail",
  data() {
    return {
      id: null,
      title:{},
      image:{},
      info:{},
      content:{},
      next_page:{},
      loadingBg: false,
    }
  },

  created() {

    this.loadingBg = true;
    this.id = this.$route.params.id; //拿到id
    getNewsDetail(this.id).then(res => { //请求接口
      this.title = res.data.data.title;
      this.image =  res.data.data.image;
      this.info = res.data.data.info;
      this.content = res.data.data.content;
      this.next_page =res.data.data.next_page;
      this.loadingBg = false;
    })

  },
  methods:{

    returnClick() {
      // this.$router.meta.keepalive = true;
      this.$router.back();
    },
    nextNews() {
      this.$router.push('/newsDetail/' + this.next_page.id);//传递id
      // location.reload()
    }
  },

  //浏览器返回按钮点击返回
  beforeRouteLeave(to, form, next) {
    to.meta.keepalive = true;
    next()
  },

}
</script>

<style>

.f5-bg{
  width: 100%;
  height: 800px;
  background: #F5F5F6;
}

.padding-fixed{
  padding-top: 92px;
}
.news-return{
  display: flex;
  justify-content: flex-end;
  color: #F76F01;
}
.news-content h1{
  text-align: center;
  color: #F70132;
}

img.cover-img{
  padding: 30px 0;
}
.news-editor p{
  padding: 5px 0;
  overflow: hidden;
}
.news-editor p img{
  padding: 10px 0;
  max-width: 100%!important;
  width: inherit!important;
  height: inherit!important;
  display: block;
  /*margin: auto;*/
}

.news-editor iframe{
  width: 800px!important;
  height: 450px!important;
}


.next-news{
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: bold;
}
.next-news-content{
  display: flex;
  padding-left: 10px;
  cursor: pointer;
}
.ac-arr{
  width: 30px;
  height: 30px;
  background: rgba(247, 1, 50, 0.4);
  border-radius: 22px;
  margin-left: 10px;
}
.ac-arr i{
  display: block;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.next-news-title{
  border-bottom: 2px solid #F70132;
}

@media only screen and (min-width:0px) and (max-width:991px) {
  .padding-fixed{
    padding-top: 68px;
  }

  .news-content h1{
    font-size: 20px;
  }
  img.cover-img {
    padding: 10px 0 0 0;
  }

  .next-news{
    flex-wrap: wrap;
    font-size: 14px;
  }
  .next-news-content{
    padding-left: 0;
    padding-top: 10px;
    width: 100%;
  }


  .news-editor iframe{
    width: 100%!important;
    height: 260px!important;
  }



}

</style>